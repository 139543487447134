import React, { createContext, useState } from "react";
import RequestHandler from "../service/RequestHandler";

const FileContext = createContext();

export const FileProvider = ({ children }) => {
  const [fileData, setFileData] = useState(() => {
    const storedData = localStorage.getItem("fileData");
    return storedData ? JSON.parse(storedData) : null;
  });
  const [fileUrl, setFileUrl] = useState(null);
  const [urlForIframe, setUrlForIframe] = useState(null);

  const saveFileDataToStorage = (data) => {
    data.forEach((machine) => {
        localStorage.setItem(machine.unique_id, JSON.stringify(machine));
    })
    localStorage.setItem("fileData", JSON.stringify(data));
  };

  const fetchCsvFileFromFile = async (file) => {
    try {
      const res = await RequestHandler("upload", "POST", file);
      setFileData(res.data);
      saveFileDataToStorage(res.data);
      return true;
    } catch (error) {
      notifyError(error);
      console.error("Error fetching CSV file from file:", error);
      return false;
    }
  };

  const fetchCsvFileFromUrl = async (fileUrl) => {
    try {
      const formData = new FormData();
      formData.append("fileUrl", fileUrl);
      const res = await RequestHandler("csv", "POST", formData);
      setFileData(res.data);
      saveFileDataToStorage(res.data);
      return true;
    } catch (error) {
      notifyError(error);
      console.error("Error fetching CSV file from URL:", error);
      return false;
    }
  };

  const fetchTopic = async (topic, topic_id, machine_id) => {
    try {
      setUrlForIframe(`https://webdemo.smart-blueding.fr/render/${topic.topic_display.toLowerCase()}/${machine_id}/${topic_id}`);
      return true;
    } catch (error) {
      console.error("Error fetching topic:", error);
      return false;
    }
  };

  const initUrl = () => {
    setUrlForIframe("");
  };
  
  const initData = () => {
    setUrlForIframe(null);
  };

  function notifyError(error) {
    // Vérifions si le navigateur prend en charge les notifications
    let content = error.toString().replaceAll("Error:","");
    if (!("Notification" in window)) {
      alert("Ce navigateur ne prend pas en charge la notification de bureau");
    }
  
    // Vérifions si les autorisations de notification ont déjà été accordées
    else if (Notification.permission === "granted") {
      // Si tout va bien, créons une notification
      const notification = new Notification(content).onclick = copyError(content);
    }
  
    // Sinon, nous devons demander la permission à l'utilisateur
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        // Si l'utilisateur accepte, créons une notification
        if (permission === "granted") {
          const notification = new Notification(content).onclick = copyError(content);
        }
      });
    }

    function copyError(error){
      navigator.clipboard.writeText(error);
    }
  
    // Enfin, si l'utilisateur a refusé les notifications, et que vous
    // voulez être respectueux, il n'est plus nécessaire de les déranger.
  }
  

  return (
    <FileContext.Provider
      value={{
        fileData,
        fileUrl,
        setFileUrl,
        fetchCsvFileFromUrl,
        fetchCsvFileFromFile,
        initData,
        fetchTopic,
        urlForIframe,
        initUrl,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

export default FileContext;
